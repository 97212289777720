export const environment = {
  salesApiUrl: '',
  userPoolId: 'ap-south-1_IDdgd0UV6',
  userPoolClientId: '1pb9ddtsbom0qh4ad35uvcjbgg',
  cognitoDomain: 'stg-sales-analytics.auth.ap-south-1.amazoncognito.com',
  scopes: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
  redirectSignIn: [`${window.location.origin}`],
  redirectSignOut: [`${window.location.origin}/login`],
  responseType: 'token',
  zohoCrmBaseUrl: 'https://crm.zoho.com/crm/org709483323',
  googleMeasurementId: 'G-N7Z6G2E5GW',
  isLocalDevMode: false
};
