import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private defaultMessageParams: Message = {
    life: 5000,
    closable: true,
  };

  constructor(private messageService: MessageService) {}

  private addMessage(message: Message) {
    this.messageService.add({ ...this.defaultMessageParams, ...message });
  }

  private successMessage(
    detail: string,
    summary = 'Success',
    message?: Omit<Message, 'severity' | 'summary' | 'detail'>,
  ) {
    this.addMessage({ severity: 'success', detail, summary, ...message });
  }

  private warningMessage(
    detail: string,
    summary = 'Warning',
    message?: Omit<Message, 'severity' | 'summary' | 'detail'>,
  ) {
    this.addMessage({ severity: 'warn', detail, summary, ...message });
  }

  private errorMessage(detail: string, summary = 'Error', message?: Omit<Message, 'severity' | 'summary' | 'detail'>) {
    this.addMessage({ severity: 'error', detail, summary, ...message });
  }

  private infoMessage(detail: string, summary = 'Info', message?: Omit<Message, 'severity' | 'summary' | 'detail'>) {
    this.addMessage({ severity: 'info', detail, summary, ...message });
  }

  public errorSignIn(detail = 'An error occurred while signing in') {
    this.errorMessage(detail);
  }

  public errorSignOut(detail = 'An error occurred while signing out') {
    this.errorMessage(detail);
  }

  public errorApi(detail = 'An API error occurred while processing your request') {
    this.errorMessage(detail, 'API Error');
  }

  public mediaPlayerError(detail = 'Unable to play media') {
    this.errorMessage(detail, 'Media Player Error');
  }
}
