import { Inject, Injectable, signal } from '@angular/core';
import {
  GOOGLE_ANALYTICS_EVENT_CATEGORIES,
  GOOGLE_ANALYTICS_EVENT_NAMES,
} from '../shared/constants/google-analytics.constants';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const gtag: any;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  isEnabled = signal(false);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadGoogleAnalytics() {
    const measurementId = environment.googleMeasurementId;
    if (!measurementId) {
      console.log('No measurement Id found');
      return;
    }
    if (this.isEnabled()) {
      return;
    }
    const script = this.document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.async = true;
    this.document.getElementsByTagName('head')[0].appendChild(script);

    const gtagEl = this.document.createElement('script');
    const gtagBody = this.document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    `);
    gtagEl.appendChild(gtagBody);
    this.document.body.appendChild(gtagEl);

    gtag('js', new Date());
    gtag('config', measurementId);
    this.isEnabled.set(true);
  }

  logEvent(eventName: string, category: string, value: object) {
    if (!this.isEnabled()) {
      this.loadGoogleAnalytics();
    }
    gtag('event', eventName, {
      event_category: category,
      event_label: eventName,
      value: value,
    });
  }

  logSignIn(payload: { username: string; userId: string }) {
    this.logEvent(GOOGLE_ANALYTICS_EVENT_NAMES.USER_LOGIN, GOOGLE_ANALYTICS_EVENT_CATEGORIES.AUTH, {
      user_id: payload.userId || 'unknown',
      method: 'sso',
    });
  }
}
